$(document).ready(function() {
    //dropdown Main Menu
    var sub_menu = $('.sub_menu');
    sub_menu.each(function (){
      if($(this).offset().left + $(this).width() > $('#menu').offset().left + $('#menu').width()){
          $(this).css('right', '0', 'left', 'auto');
    }});

    // Active menu
    $(function () {
        $('#menu a').each(function () {
            var location = window.location.href;
            var link = this.href;
            if(location == link) {
                $(this).addClass('active');
                $(this).parents('.sub_menu').siblings('.top-menu_link_js').addClass('active');
                $(this).parents('.has-child').find('.mob-dropdown').addClass('active-mob');
            }
        });
    });

    $(document).on('click touchstart', '.dop-menu-dropdown', function (e) {
        $(this) && e.stopPropagation();
    });
    $(document).on('click touchstart', '.btn-menu', function (e) {
        $('.dop-menu-dropdown') && e.stopPropagation();
    });

    // Main menu
    // if (($(window).width() > (767)) && ($(window).width() < (1000))){
    //     $('.top-menu_link_js').on('click', function(e)  {
    //         e.preventDefault();
    //     });
    //     $('.has-child').click(function (e) {
    //         $('.sub_menu').css({'height':'0', 'padding-top':'0'});
    //         $(this).find('.sub_menu_contein').css({'padding':'0 15px'});
    //         $(this).find('.sub_menu').css({'height':'auto', 'padding-top':'20px'});
    //         $(this).find('.sub_menu_contein').css({'padding':'23px 15px'});
    //     })
    // }

    // mob Main Menu
    $('#menu-btn').click(function(){
        $('#menu').toggleClass('active');
        $(this).toggleClass('active');
    });
    
    $(window).resize(function () {
        var curW = $(this).width();
        if (curW > (750)) {
            $('#menu').removeClass('active');
            $('#menu-btn').removeClass('active');
        }
        if (curW < (768) || curW > (992)  ) {
            $('#menu').find('.sub_menu').removeClass("tablet-list");
            $('#menu').find('.has-child').removeClass('active');
        }
    });


    $('.mob-dropdown').click(function(){
        $(this).siblings('.sub_menu').slideToggle();
        $(this).toggleClass('active');
        $(this).parents('.has-child').siblings('.has-child').find('.sub_menu').slideUp();
        $(this).parents('.has-child').siblings('.has-child').find('.mob-dropdown').removeClass('active');

    });
    $('.menu_stage_1>.has-child>.mob-dropdown').click(function(){
        $(this).parents('.menu_stage_1>.has-child').toggleClass('active_mob');
        $(this).parents('.menu_stage_1>.has-child').siblings('.menu_stage_1>.has-child').removeClass('active_mob');

    });

    //Search
    $('.open_search_js').on('click', function(e) {
        $('.search-top').addClass('active');
    });
    $('.close-mod').on('click', function(e) {
        $('.search-top').removeClass('active');
    });

    // Nav-menu 
    $('.caret-container').click(function(){
        if ($(this).parents('.has-child').find('.sub_menu').hasClass('tablet-list') ) {
            $(this).parents('.has-child').find('.sub_menu').removeClass("tablet-list");
        } else {
            $('#menu').find('.sub_menu').removeClass('tablet-list');
            $(this).parents('.has-child').find('.sub_menu').addClass('tablet-list');          
        }
        if ($(this).parents('.has-child').hasClass('active_sm') ) {
            $(this).parents('.has-child').removeClass('active_sm');
        } else {
            $('#menu').find('.has-child').removeClass('active_sm');
            $(this).parents('.has-child').addClass('active_sm');
        }
    });
 

});